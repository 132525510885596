<template>
    <div class="news-index page normal-page">
        <div class="page-banner swiper-block">
            <div class="swiper banner-swiper">
                <div class="swiper-wrapper">
                    <div
                        v-for="(item, index) in banner"
                        :key="item.id"
                        class="swiper-slide"
                        :data-swiper-slide-index="index"
                    >
                        <img
                            class="is-desktop"
                            :src="image(item.imageDes)"
                            alt=""
                        >
                        <img
                            class="is-mobile"
                            :src="image(item.imageMob)"
                            alt=""
                        >
                    </div>
                </div>
                <div class="swiper-pagination banner-swiper-pagination" />
                <div class="swiper-button-prev banner-swiper-button-prev" />
                <div class="swiper-button-next banner-swiper-button-next" />
            </div>
        </div>
        <div class="web-content">
            <div class="page-structure">
                <PageMenu :menu-data="pageMenu" />
                <div class="page-content">
                    <router-view />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import API from '@/api';
import { mapMutations } from 'vuex';
import Swiper, { Autoplay, Pagination, Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import PageMenu from '@/components/PageMenu.vue';

Swiper.use([Autoplay, Pagination, Navigation]);
export default {
    components: {
        PageMenu,
    },
    data() {
        return {
            banner: [],
            pageMenu: {
                // eslint-disable-next-line global-require
                icon: require('@/assets/news/menu-icon.png'),
                title: 'news.pageMenu.title',
                items: [
                    {
                        type: 'router',
                        text: 'news.pageMenu.items.item1.text',
                        routeName: 'NewsIndex',
                        hash: '#now',
                    },
                    {
                        type: 'router',
                        text: 'news.pageMenu.items.item2.text',
                        routeName: 'NewsIndex',
                        hash: '#social-innovation',
                    },
                    {
                        type: 'router',
                        text: 'news.pageMenu.items.item3.text',
                        link: '/news#teams',
                        routeName: 'NewsIndex',
                        hash: '#teams',
                    },
                ],
            },
        };
    },
    watch: {
        $route() {
            this.init();
        },
    },
    mounted() {
        this.init();
        this.initSwiper();
    },
    methods: {
        ...mapMutations('mNotification', ['setAlert', 'handleError']),
        async init() {
            try {
                const res = await API.ArticleBanner.PublicList({
                    headers: {
                        'Content-Language': (this.$route.path.split('/')[1] || 'zhTw') === 'en' ? 'en' : 'zhTw',
                    },
                });
                this.banner = res.data.raw;
                this.showData = true;
            } catch (e) {
                this.handleError({
                    message: e || this.$t('alertMessage.getdata-fail'),
                    color: 'error',
                });
            }
        },
        initSwiper() {
            // eslint-disable-next-line no-new
            new Swiper('.banner-swiper', {
                loop: true,
                pagination: {
                    el: '.banner-swiper-pagination',
                },
                navigation: {
                    nextEl: '.banner-swiper-button-next',
                    prevEl: '.banner-swiper-button-prev',
                },
                autoplay: {
                    delay: 5000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false,
                },
            });
        },
        image(item) {
            const file = `https://${process.env.VUE_APP_BASE_URL + item}`;
            return file;
        },
    },
};
</script>

<style lang="scss">
.news-index{
    .swiper-block {
        width: 100vw;
        margin-bottom: 45px;
    }
    .banner-swiper {
        width: 100vw;
        height: 300/1440*100vw;
        overflow: hidden;
        .is-mobile {
            display: none;
        }
        .swiper-slide {
            img {
                width: 100vw;
                object-fit: contain;
            }
        }
        .swiper-pagination-bullet {
          background-color: white;
        }
        .swiper-pagination-bullet-active {
          --swiper-theme-color: #f97e2b;
          background-color: #f97e2b;
        }
        .swiper-button-next,
        .swiper-button-prev {
          --swiper-theme-color: #f97e2b;
        }
    }
}

@media screen and (max-width: 960px) {
    .news-index{
        .banner-swiper {
            height: 200/375*100vw;
            .is-desktop {
                display: none !important;
            }
            .is-mobile {
                display: block;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .news-index{
        .swiper-block {
            margin-bottom: 30px;
        }
    }
}
</style>
